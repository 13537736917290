import _ from 'lodash';

import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { ForgeButton, ForgeOption, ForgePageState, ForgeSelect } from '@tylertech/forge-react';
import { localizeLink } from 'common/locale';

import BootstrapAlert from './BootstrapAlert';
import { RELATED_VIEWS_FETCH_LIMIT } from '../lib/constants';
import { handleKeyPress } from 'common/dom_helpers/keyPressHelpers';
import { FeatureFlags } from 'common/feature_flags';
import {
  FilterType,
  SortType,
  fetchRelatedViews,
  dismissRelatedViewsError,
  toggleRelatedViews
} from '../actions/relatedViews';
import { View } from 'common/types/view';
import I18n from 'common/i18n';
import SearchInput from 'common/components/SearchInput';
import AssetCard from 'common/components/AssetCard';

const t = (k: string, options: any = {}) =>
  I18n.t(k, { scope: 'dataset_landing_page.related_views', ...options });

export interface RelatedViewListProps {
  dismissError: () => void;
  hasError: boolean;
  hasMore: boolean;
  isCollapsed: boolean;
  isDesktop: boolean;
  isLoading: boolean;
  loadMore: (a: string, b: FilterType, c: SortType) => void;
  reloadViews: (a: string, b: FilterType, c: SortType) => void;
  onClickWidget: () => void;
  toggleList: () => void;
  viewList: View[];
  parentUpdatedAt?: string;
  bootstrapUrl?: string;
  relatedViewCount: number;
}

interface RelatedViewListState {
  searchText?: string;
  showFilter: FilterType;
  sortBy: SortType;
  unfilteredViewCount: number;
}

export class RelatedViewList extends Component<RelatedViewListProps, RelatedViewListState> {
  constructor(props: RelatedViewListProps) {
    super(props);

    _.bindAll(this, [
      'onScrollList',
      'getHeight',
      'renderContents',
      'renderLoadMoreLink',
      'renderError',
      'renderCollapseLink',
      'onUpdateSearch',
      'renderFilters',
      'onFilterChange',
      'onSortChange'
    ]);

    this.state = {
      showFilter: FilterType.AllViews,
      sortBy: SortType.Popularity,
      unfilteredViewCount: props.relatedViewCount
    };
  }

  onScrollList(event: React.UIEvent<HTMLElement>) {
    const { isDesktop, hasMore, isLoading, loadMore } = this.props;

    if (isDesktop || !hasMore || isLoading) {
      return;
    }

    const el = event.target as HTMLElement;
    const isAtRightEdge = el.scrollWidth - el.offsetWidth - el.scrollLeft < 200;

    if (isAtRightEdge) {
      loadMore(this.state.searchText || '', this.state.showFilter, this.state.sortBy);
    }
  }

  getHeight() {
    const { viewList, isCollapsed, isDesktop, isLoading } = this.props;

    if (_.isEmpty(viewList)) {
      return;
    }

    const relatedViewHeight = 297;
    const relatedViewMargin = 18;

    if (!isDesktop) {
      return {
        height: '100%'
      };
    }

    const visibleCount = isCollapsed ? RELATED_VIEWS_FETCH_LIMIT : viewList.length;
    let rowCount = Math.ceil(visibleCount / RELATED_VIEWS_FETCH_LIMIT);

    // While loading on desktop, we immediately expand the container to make room for the new views.
    if (isLoading) {
      rowCount += 1;
    }

    return (relatedViewHeight + relatedViewMargin) * rowCount - 1;
  }

  renderContents() {
    const { parentUpdatedAt, viewList, hasMore, onClickWidget, isDesktop, isLoading, isCollapsed } =
      this.props;

    const relatedViews = _.map(
      viewList.slice(0, isCollapsed ? RELATED_VIEWS_FETCH_LIMIT : viewList.length),
      (relatedView, i) => {
        return (
          //@ts-ignore -- View interface lies and has a lot of properties not listed on it and that are required here
          <AssetCard key={i} {...relatedView} onClick={onClickWidget} parentRowsUpdatedAt={parentUpdatedAt} />
        );
      }
    );

    if (isDesktop && hasMore && isLoading) {
      relatedViews.push(
        <div className="desktop-spinner" key="loading">
          <span className="spinner-default spinner-large" />
        </div>
      );
    }

    const animatedViews = relatedViews.map((view, idx) => {
      return (
        <CSSTransition
          key={idx}
          in={true}
          onScroll={this.onScrollList}
          classNames="related-views"
          timeout={{ enter: 400, exit: 400 }}
        >
          {view}
        </CSSTransition>
      );
    });

    return <TransitionGroup className="media-results">{animatedViews}</TransitionGroup>;
  }

  renderLoadMoreLink() {
    const { hasMore, isLoading, loadMore } = this.props;

    if (!hasMore) {
      return null;
    }

    const clickHandler = isLoading
      ? null
      : () => loadMore(this.state.searchText || '', this.state.showFilter, this.state.sortBy);

    return (
      <ForgeButton
        type="outlined"
        className="load-more-button"
        data-testid={'load-more-related-content-button'}
        onClick={clickHandler}
        onKeyDown={handleKeyPress(clickHandler)}
      >
        <button type="button" tabIndex={0}>
          <span>{I18n.t('dataset_landing_page.more')}</span>
        </button>
      </ForgeButton>
    );
  }

  renderError() {
    const { hasError, dismissError } = this.props;

    if (!hasError) {
      return null;
    }

    return (
      <div className="alert error">
        {t('load_more_error')}
        <span
          data-testid="dismiss-load-more-error"
          role="button"
          className="icon-close-2 alert-dismiss"
          onClick={dismissError}
        ></span>
      </div>
    );
  }

  renderCollapseLink() {
    const { viewList, hasMore, isCollapsed, toggleList, isDesktop } = this.props;

    if (hasMore || viewList.length <= RELATED_VIEWS_FETCH_LIMIT || !isDesktop) {
      return null;
    }

    return (
      <ForgeButton type='outlined' onClick={toggleList} onKeyDown={handleKeyPress(toggleList)}>
        <button type="button" tabIndex={0} className="collapse-button">
          <span>
            {isCollapsed ? I18n.t('dataset_landing_page.more') : I18n.t('dataset_landing_page.less')}
          </span>
        </button>
      </ForgeButton>
    );
  }

  renderNoContent() {
    const { bootstrapUrl } = this.props;
    const navigateToVisualization = () => window.location.assign(localizeLink(bootstrapUrl!));

    if (this.state.unfilteredViewCount === 0) {
      return (
        <ForgePageState className="related-views-page-state">
          <img
            src="https://cdn.forge.tylertech.com/v1/images/spot-hero/general-empty-state-spot-hero.svg"
            slot="graphic"
            alt=""
          />
          <div slot="title">{t('no_content_title')}</div>
          <p slot="message">{t('no_content_message')}</p>
          <ForgeButton type="raised" slot="action">
            <button type="button" data-testid="create-visualization-button" onClick={navigateToVisualization}>
              {t('create_visualization')}
            </button>
          </ForgeButton>
        </ForgePageState>
      );
    } else {
      return this.renderNoFilteredContent();
    }
  }

  renderNoFilteredContent() {
    return (
      <ForgePageState className="related-views-page-state">
        <img
          src="https://cdn.forge.tylertech.com/v1/images/spot-hero/no-search-results-spot-hero.svg"
          slot="graphic"
          alt=""
        />
        <div slot="title">{t('no_filtered_content_title')}</div>
        <p slot="message">{t('no_filtered_content_message')}</p>
      </ForgePageState>
    );
  }

  debouncedReloadViews = _.debounce((newSearch: string, showFilter: FilterType, sortBy: SortType) => {
    this.props.reloadViews(newSearch, showFilter, sortBy);
  }, 500);

  onUpdateSearch(newSearch: string) {
    this.setState({ searchText: newSearch });
    this.debouncedReloadViews(newSearch, this.state.showFilter, this.state.sortBy);
  }

  onFilterChange(ev: CustomEvent) {
    const filter = ev.detail;
    if (this.state.showFilter != filter) {
      this.setState({ showFilter: filter });
      this.props.reloadViews(this.state.searchText || '', filter, this.state.sortBy);
    }
  }

  onSortChange(ev: CustomEvent) {
    const sort = ev.detail;
    if (this.state.sortBy != sort) {
      this.setState({ sortBy: sort });
      this.props.reloadViews(this.state.searchText || '', this.state.showFilter, sort);
    }
  }

  renderFilters() {
    return (
      <div className="related-content-header-wrapper">
        <div className="landing-page-header-related-content">
          <div className="left-content">
            <span className="results-count">
              <strong>{this.props.relatedViewCount}</strong>{' '}
              {t('results', { count: this.props.relatedViewCount })}{' '}
            </span>
            <div className="left-filter-wrapper">
              <span className="related-content-filter">
                <ForgeSelect
                  onChange={this.onFilterChange}
                  value={this.state.showFilter}
                  label={t('asset_types.label')}
                >
                  <ForgeOption value={FilterType.AllViews}>{t('asset_types.all')}</ForgeOption>
                  <ForgeOption value={FilterType.Calendars}>{t('asset_types.calendars')}</ForgeOption>
                  <ForgeOption value={FilterType.Charts}>{t('asset_types.charts')}</ForgeOption>
                  {!FeatureFlags.value('restrict_community_access') && (
                    <ForgeOption value={FilterType.CommunityCreated}>
                      {t('asset_types.community_created')}
                    </ForgeOption>
                  )}
                  <ForgeOption value={FilterType.FilteredViews}>
                    {t('asset_types.filtered_views')}
                  </ForgeOption>
                  <ForgeOption value={FilterType.Maps}>{t('asset_types.maps')}</ForgeOption>
                  <ForgeOption value={FilterType.Measures}>{t('asset_types.measures')}</ForgeOption>
                  {!FeatureFlags.value('restrict_community_access') && (
                    <ForgeOption value={FilterType.Official}>{t('asset_types.official')}</ForgeOption>
                  )}
                  <ForgeOption value={FilterType.Stories}>{t('asset_types.stories')}</ForgeOption>
                </ForgeSelect>
              </span>
              <span className="related-content-filter">
                <ForgeSelect
                  onChange={this.onSortChange}
                  value={this.state.sortBy}
                  label={t('sort_by.label')}
                >
                  <ForgeOption value={SortType.Popularity}>{t('sort_by.most_viewed')}</ForgeOption>
                  <ForgeOption value={SortType.MostRecent}>{t('sort_by.newest_oldest')}</ForgeOption>
                  <ForgeOption value={SortType.OldestNewest}>{t('sort_by.oldest_newest')}</ForgeOption>
                  <ForgeOption value={SortType.RecentlyUpdated}>{t('sort_by.recently_updated')}</ForgeOption>
                  <ForgeOption value={SortType.AtoZ}>{t('sort_by.alpha')}</ForgeOption>
                  <ForgeOption value={SortType.ZtoA}>{t('sort_by.alpha_desc')}</ForgeOption>
                </ForgeSelect>
              </span>
            </div>
          </div>
          <div className="right-content">
            <SearchInput
              title={t('search_label')}
              value={this.state.searchText || ''}
              onChange={this.onUpdateSearch}
              onSearch={() => {}}
              dataTestId="related-content-search"
              id="related-content-search"
            />
          </div>
        </div>
      </div>
    );
  }

  renderRelatedContent() {
    return (
      <>
        {this.renderContents()}
        {this.renderLoadMoreLink()}
        {this.renderCollapseLink()}
        {this.renderError()}
      </>
    );
  }

  render() {
    const { viewList } = this.props;

    return (
      <section className="landing-page-section related-views related-views-page-state">
        {this.state.unfilteredViewCount > 0 && this.renderFilters()}
        {_.isEmpty(viewList) && this.renderNoContent()}
        {!_.isEmpty(viewList) && this.renderRelatedContent()}
      </section>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    bootstrapUrl: state.view.bootstrapUrl,
    ...state.relatedViews
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loadMore(searchText: string, filterBy: FilterType, sortBy: SortType) {
      dispatch(fetchRelatedViews(searchText, filterBy, sortBy, false));
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    },

    reloadViews(searchText: string, filterBy: FilterType, sortBy: SortType) {
      dispatch(fetchRelatedViews(searchText, filterBy, sortBy, true));
    },
    toggleList() {
      dispatch(toggleRelatedViews());
    },

    dismissError() {
      dispatch(dismissRelatedViewsError());
    },

    onClickWidget(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      const resultCard = $(event.target).closest('.result-card')[0];

      if (resultCard) {
        // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedViewList);
